<template>
	<div>
		<div class="row">
			<div class="col-md-4">
				<h3 class="text-muted">A partir del 7 de Agosto de 2024</h3>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12 text-center">
				<div class="card bg-primary border-0">
					<div class="card-body">
						<div class="text-white font-weight-bold">
							<div class="font-size-21">Total de ahorros</div>
							<div class="font-size-18">{{ numeral(localData.resume?.s2r + localData.resume?.rls).format('$0,0.00') }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6 text-center">
				<div class="card bgGreen border-0 pointer pacific">
					<div class="card-body pointer">
						<div class="font-weight-bold">
							<div class="font-size-21">S2R</div>
							<div class="font-size-14">
								<div class="row">
									<div class="col-md-12 text-center">
										{{ numeral(localData.resume?.s2r).format('$0,0.00') }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6 text-center">
				<div class="card bgRls border-0 pointer rls">
					<div class="card-body pointer">
						<div class="font-weight-bold">
							<div class="font-size-21">RLS</div>
							<div class="font-size-14">
								<div class="row">
									<div class="col-md-12 text-center">
										{{ numeral(localData.resume?.rls).format('$0,0.00') }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-4">
				<b>Empresa</b>
				<a-select style="width: 100%" v-model="payload.company" @change="initModule">
					<a-select-option value="" selected>Todas</a-select-option>
					<a-select-option value="s2r">S2R</a-select-option>
					<a-select-option value="rls">RLS</a-select-option>
				</a-select>
			</div>
			<div class="col-md-4">
				<b>Fecha</b>
				<a-range-picker style="width: 100%" v-model="payload.date" :default-value="payload.date" :format="'DD-MM-YYYY'" @change="initModule" />
			</div>
			<div class="col-12">
				<!-- <button @click="initModule">Carga</button> -->
				<hr class="hrDotted" />
			</div>
			<div class="col-md-12">
				<a-table :columns="columns" :dataSource="localData.list">
					<div slot="company" slot-scope="record" class="text-uppercase">
						{{ record.company }}
					</div>
					<div slot="amount" slot-scope="record" class="text-uppercase">
						{{ numeral(record.amount).format('$0,0') }}
					</div>
					<div slot="created_at" slot-scope="record" class="text-uppercase">
						{{ moment(record.created_at).format('DD-MM-YYYY') }}
					</div>
				</a-table>
			</div>
		</div>
	</div>
</template>
<script>
import _ from 'lodash'
import utilities from '@/services/utilities'
import numeral from 'numeral'
import moment from 'moment'

const dateFormat = 'YYYY-MM-DD'
//
export default {
	name: 'savingsComponent',
	directives: {},
	computed: {},
	data() {
		return {
			dateFormat,
			localData: {},
			payload: {
				date: [moment().startOf('month').format(dateFormat), moment(moment().endOf('month')).format(dateFormat)],
				company: '',
			},
			columns: [
				{
					title: '#',
					align: 'center',
					dataIndex: 'id',
				},
				{
					title: 'Empresa',
					align: 'center',
					scopedSlots: { customRender: 'company' },
				},
				{
					title: 'Descripción',
					align: 'center',
					dataIndex: 'description',
				},
				{
					title: 'Importe',
					align: 'center',
					scopedSlots: { customRender: 'amount' },
				},
				{
					title: 'Fecha',
					align: 'center',
					scopedSlots: { customRender: 'created_at' },
				},
			],
		}
	},
	mounted() {
		this.initModule()
	},
	methods: {
		numeral,
		moment,
		initModule() {
			this.$store.dispatch('savings/GET', this.payload).then((response) => {
				this.localData = _.cloneDeep(utilities.objectValidate(response, 'data', {}))
			})
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>