<template>
	<a-spin :tip="spinnerLoaderLabel || spinnerLoaderLabelBilling" size="large" :spinning="spinnerLoader || spinnerLoaderBilling">
		<div class="row">
			<div class="col-md-3">
				<b>Empresa</b>
				<a-select style="width: 100%" v-model="payload.company" @change="generateReport">
					<a-select-option value="" selected>Selecciona</a-select-option>
					<a-select-option value="from_pacific">S2R</a-select-option>
					<a-select-option value="from_rls">RLS</a-select-option>
				</a-select>
			</div>
			<div class="col-md-3">
				<b>Tipo de reporte</b>
				<a-select style="width: 100%" v-model="payload.type" @change="generateReport">
					<a-select-option value="" selected>Selecciona</a-select-option>
					<a-select-option value="download">Descarga de facturas</a-select-option>
					<a-select-option value="-" disabled>----------</a-select-option>
					<a-select-option value="total">Totales de Caja</a-select-option>
					<a-select-option value="balance">Balance de Caja</a-select-option>
					<a-select-option value="daily">Diario de Ventas</a-select-option>
					<a-select-option value="invoices">Facturas emitidas</a-select-option>
					<a-select-option value="canceled_tickets">Tickets cancelados</a-select-option>
					<a-select-option value="--" disabled>----------</a-select-option>
					<a-select-option value="to_bill_tickets">Tickets por facturar</a-select-option>
					<a-select-option value="monthly_bill">Facturación Mensual</a-select-option>
				</a-select>
			</div>
			<div class="col-md-3">
				<b>Ubicación</b>
				<a-select style="width: 100%" v-model="payload.branch_office_id" @change="generateReport" :disabled="payload.type == 'download'">
					<a-select-option value="" selected>Todas</a-select-option>
					<a-select-option value="1">Matriz</a-select-option>
					<a-select-option value="2">Centro</a-select-option>
				</a-select>
			</div>
			<div class="col-md-3">
				<b>Rango de fecha</b>
				<a-range-picker style="width: 100%" v-model="payload.date" :format="dateFormat" @change="generateReport" />
			</div>
		</div>
		<div class="row pt20" v-if="thePDF.base64 != ''">
			<div class="col-md-12 text-right">
				<a-button class="btn btn-primary" @click="downloadReport">Descargar reporte</a-button>
			</div>
			<div class="col-md-12 pt10">
				<object :data="`data:application/pdf;base64,${thePDF.base64}`" type="application/pdf" width="100%" height="600px"></object>
			</div>
		</div>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
const dateFormat = 'DD-MM-YYYY'
let { VUE_APP_API_URL } = process.env

export default {
	name: 'posReportsComponent',
	computed: {
		...mapGetters('pos', ['spinnerLoaderLabel', 'spinnerLoader']),
		...mapGetters('branchOffices', ['branchOfficesList']),
		spinnerLoaderBilling() {
			return this.$store.state.billing.spinnerLoader
		},
		spinnerLoaderLabelBilling() {
			return this.$store.state.billing.spinnerLoaderLabel
		},
	},
	data() {
		return {
			dateFormat,
			payload: {
				company: '',
				branch_office_id: '',
				type: '',
				date: null,
			},
			thePDF: {
				base64: '',
				filename: '',
			},
		}
	},
	mounted() {
		if (this.branchOfficesList.length == 0) {
			this.$store.dispatch('branchOffices/GET')
		}
	},
	methods: {
		generateReport() {
			if (this.payload.type == '-' || this.payload.type == '--') {
				return false
			}
			let errors = []

			if (this.payload.company == '') {
				errors.push('- Debes seleccionar empresa')
			}

			if (this.payload.type != 'download' && this.payload.type == '') {
				errors.push('- Debes seleccionar tipo de reporte')
			}

			if (this.payload.date == null) {
				errors.push('- Debes seleccionar fecha')
			}

			this.thePDF = {
				base64: '',
				filename: '',
			}

			if (!errors.length) {
				let thePayload = {
					startDate: moment(this.payload.date[0]).format('YYYY-MM-DD'),
					endDate: moment(this.payload.date[1]).format('YYYY-MM-DD'),
					company: this.payload.company,
					branch_office_id: this.payload.branch_office_id,
					type: this.payload.type,
				}

				if (this.payload.type == 'download') {
					this.$store.dispatch('billing/DOWNLOAD_ZIP', thePayload).then((response) => {
						window.open(`${VUE_APP_API_URL}${response.data.url}`)
					})
				} else {
					this.$store.dispatch('pos/GET_PDF_REPORT', thePayload).then((response) => {
						this.thePDF = response.data
					})
				}
			}
		},
		downloadReport() {
			const linkSource = `data:application/pdf;base64,${this.thePDF.base64}`
			const downloadLink = document.createElement('a')
			downloadLink.href = linkSource
			downloadLink.download = this.thePDF.filename
			downloadLink.click()
		},
	},
}
</script>